import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { CheckCircle, XCircle } from "lucide-react";
import * as Diff from "diff";
import { diffWords } from "diff";
import { Suggestion } from "../../types/TypesAndFunctions";

interface SuggestionHandlerProps {
    suggestions: Suggestion[];
    acceptedSuggestions: Suggestion[];
    setAcceptedSuggestions: (acceptedSuggestions: Suggestion[]) => void;
}

const SuggestionHandler: React.FC<SuggestionHandlerProps> = ({ suggestions, acceptedSuggestions, setAcceptedSuggestions }) => {
    const toggleAccept = (suggestion: Suggestion) => {
        setAcceptedSuggestions((prev) =>
            prev.includes(suggestion)
                ? prev.filter((s) => s !== suggestion)
                : [...prev, suggestion]
        );
    };
    if (suggestions.length === 0) return <div>No suggestions available.</div>;

    const highlightDifferences = (original: string, suggested: string) => {
        const diff = diffWords(original, suggested);

        return diff.map((part, index) => (
            <span
                key={index}
                style={{
                    textDecoration: part.removed ? "line-through" : "none",
                    color: part.added ? "green" : part.removed ? "red" : "",
                    marginRight: part.removed ? "4px" : "0", // Adds spacing after removed text

                    fontWeight: part.added ? "bold" : "normal",

                }}
            >
                {part.value}
            </span>
        ));
    };

    return (
        <div className="h-[60vh] p-4 rounded-lg shadow-md bg-gray-800 overflow-y-auto flex flex-col mt-5">
            <h2 className=" text-gray-50  mb-4 text-center">
                <span className="text-md font-bold">{"Review Suggestions from AI"}</span>
                <span className="text-sm ml-2">{"(Amount: " + suggestions.length + ")"}</span>
            </h2>
            <div className="space-y-4 flex-grow overflow-y-auto">
                {suggestions.map((suggestion, index) => (
                    <motion.div
                        key={index}
                        className={`text-gray-50 p-1 text-xs rounded-lg flex justify-between items-center border border-transparent hover:border-gray-500 bg-gray-700 ${acceptedSuggestions.includes(suggestion) ? "border-green-300 hover:border-green-200" : ""}`}
                        whileHover={{ scale: 1.0 }}
                        onClick={() => toggleAccept(suggestion)}
                    >
                        <div className="w-[95%] h-full p-4">
                            <p className="text-sm mb-0.5 p-1"><strong>Section:</strong> {suggestion.section}</p>

                            <div className="flex gap-2">
                                <p className="text-xs mb-0.5 p-1"><strong>Original:</strong> {suggestion.original_text}</p>
                                <p className="text-xs mb-0.5 p-1"><strong>Suggested:</strong> {highlightDifferences(suggestion.original_text, suggestion.suggested_text)}</p>
                            </div>
                        </div>


                        {acceptedSuggestions.includes(suggestion) ? (
                            <CheckCircle className="h-6 w-6 text-green-500" size={24} />
                        ) : (
                            <XCircle className="h-6 w-6 text-red-500" size={24} />
                        )}
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default SuggestionHandler;