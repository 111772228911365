import React from 'react';
import ReactDOM from 'react-dom/client'; // ✅ Correct import for React 18+
import './index.css';
import ContractRedlineTool from './App.tsx';
import reportWebVitals from './reportWebVitals.ts';

// ✅ Create a root and render the app correctly in React 18+
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
try {
  root.render(
    <React.StrictMode>

      <ContractRedlineTool />
    </React.StrictMode>
  );
} catch (err) {
  console.log("OOPS! Error")
  console.log(err)
}

// Start measuring performance
reportWebVitals();
