import React, { useState, useRef, useEffect } from 'react';
import { Pencil } from 'lucide-react';
import { Artist, ContractDetailsChanges } from '../../types/TypesAndFunctions.tsx';

const ContractSummaryEntry: React.FC<{ entryId: string, entryKey: string, entryValue: string, editedValue: string, isEditable: boolean, contractDetails: ContractDetailsChanges, setContractDetails: (newContractDetails: ContractDetailsChanges | null) => void, artist: Artist | null }> = ({ entryId, entryKey, entryValue, editedValue, isEditable = false, contractDetails, setContractDetails, artist }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(entryValue);
    const [newValue, setNewValue] = useState(editedValue);
    const [imageIsLoaded, setImageIsLoaded] = useState(false);

    const openModal = () => setIsEditing(true);
    const closeModal = () => setIsEditing(false);

    const handleSave = () => {
        if (newValue.trim()) {
            setValue(newValue);
            setContractDetails((prev) => ({
                ...prev,
                modified: {
                    ...prev.modified,
                    [entryId]: newValue,
                }
            }));
        }
        setIsEditing(false);
    };

    return (
        <div className="flex justify-between border-b border-blue-400 py-2">
            <span className="w-1/3 text-md">{entryKey}:</span>

            <span className="w-8/12 text-sm italic flex items-center gap-2">
                {artist && entryKey.includes("Artist") ? (
                    <>
                        {artist.image !== "" && artist.name !== "" && (
                            <img
                                src={artist.image}
                                alt={artist.name}
                                onLoad={() => setImageIsLoaded(true)}
                                className="w-16 h-16 rounded-full object-fill"
                            />
                        )}
                        {artist.url !== "" && artist.name !== "" && (
                            <a
                                href={artist.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-400 hover:underline text-xl"
                            >
                                {artist.name}
                            </a>
                        )}
                        {artist.genres !== "" && (
                            <span className="text-gray-300 text-xs">({artist.genres})</span>
                        )}
                    </>
                ) : editedValue !== entryValue ? (
                    <>
                        <span className="line-through text-gray-400">{entryValue}</span>
                        <span className="text-white">{editedValue}</span>
                    </>
                ) : (
                    <>
                        {entryKey.includes("Artist") && entryValue !== "" && !imageIsLoaded && <div className="w-16 h-16 rounded-full bg-gray-600" />}
                        <span>{value}</span>
                    </>
                )}
            </span>

            <div className="w-1/12 flex justify-center items-center">
                {isEditable &&
                    <Pencil
                        className="w-4 h-4 cursor-pointer text-blue-400 hover:text-blue-600"
                        onClick={openModal}
                    />
                }
            </div>

            {/* Modal */}
            {isEditing && (
                <div className="text-black fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-[50%]">
                        <h2 className="text-lg font-semibold mb-4">Edit "{entryKey}"</h2>
                        <div className="flex justify-start items-center gap-10 my-5">
                            <p className="text-xs font-semibold">Original:</p>
                            <p className="text-xs font-semibold">{entryValue}</p>
                        </div>
                        <div className="flex justify-start items-center gap-10 my-5">
                            <p className="text-xs font-semibold">New:</p>
                            <textarea
                                value={newValue || entryValue}
                                onChange={(e) => setNewValue(e.target.value)}
                                rows={5}
                                className="w-full border px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                autoFocus
                            />
                        </div>
                        <div className="flex justify-end gap-2 mt-4">
                            <button
                                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                            <button
                                className={`px-4 py-2 text-white rounded ${newValue === "" ? "bg-gray-500" : "bg-blue-500 hover:bg-blue-600"}`}
                                onClick={() => {
                                    if (newValue !== "") {
                                        handleSave();
                                    }
                                }}
                                disable={newValue == ""}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};


export default ContractSummaryEntry;
