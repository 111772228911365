import React, { useEffect, useRef } from "react";
import { renderAsync } from "docx-preview"; // Import the renderAsync function

interface DocViewerProps {
    file: Blob | ArrayBuffer | Uint8Array; // the document data to render
}

const DocViewer: React.FC<DocViewerProps> = ({ file }) => {
    const bodyContainerRef = useRef<HTMLElement | null>(null);
    const styleContainerRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        if (bodyContainerRef.current) {
            // Render the document when the component mounts
            renderAsync(
                file,
                bodyContainerRef.current,
                styleContainerRef.current || bodyContainerRef.current,
                {
                    className: "docx", // you can change this className as needed
                    inWrapper: true,
                    hideWrapperOnPrint: false,
                    ignoreWidth: false,
                    ignoreHeight: false,
                    ignoreFonts: false,
                    breakPages: false,
                    ignoreLastRenderedPageBreak: true,
                    experimental: false,
                    trimXmlDeclaration: true,
                    useBase64URL: false,
                    renderChanges: false,
                    renderHeaders: true,
                    renderFooters: true,
                    renderFootnotes: true,
                    renderEndnotes: true,
                    renderComments: false,
                    renderAltChunks: true,
                    debug: false,
                }
            ).then(() => {
                console.log("Document rendered successfully.");
            }).catch((error) => {
                console.error("Error rendering document:", error);
            });
        }

        if (styleContainerRef.current) {
            const styleElement = document.createElement("style");
            styleElement.innerHTML = `
                .docx {
                    font-size: 1rem; /* Adjust the font size here */
                }
            `;
            styleContainerRef.current.appendChild(styleElement);
        }
    }, [file]); // Run whenever the document prop changes

    return (
        <div className="w-full h-full flex justify-center items-center overflow-auto rounded-lg">
            <div
                ref={bodyContainerRef}
                id="body-container"
                className="w-full h-full overflow-auto"
                style={{
                    maxHeight: "500px", // Take full height of the parent
                    maxWidth: "100%", // Take full width of the parent
                    overflow: "auto", // Allow scrolling when content overflows
                    objectFit: "contain", // Scale the content to fit the container
                    display: "block", // Ensure the content is rendered as a block
                    fontSize: "1rem" // Font size applied here
                }}
            />
            <div
                ref={styleContainerRef}
                id="style-container"
                className="hidden" // Hide style container (if not needed to display)
            />
        </div>
    );
};

export default DocViewer;
