import "./LoadingDots.css"; // Import the CSS
import React, { useState, useRef, useEffect } from 'react';

const LoadingDots = () => {
    return (
        <div className="dots-container mt-10">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
        </div>
    );
};

export default LoadingDots;