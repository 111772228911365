import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import "react-toastify/dist/ReactToastify.css";

import { ContractDetailsChanges, ContractDetails, Artist } from '../../types/TypesAndFunctions.tsx';
import ContractSummaryEntry from './ContractSummaryEntry.tsx';

const ContractSummary: React.FC<{ contractDetails: ContractDetailsChanges, setContractDetails: (newContractDetails: ContractDetailsChanges | null) => void, editable: boolean, artist: Artist | null }> = ({ contractDetails, setContractDetails, editable, artist }) => {

    const contractDetailsMap = {
        "artist": { id: 1, displayValue: "Artist/s", editable: false },
        "producers": { id: 2, displayValue: "Producers", editable: false },
        "companyLabel": { id: 3, displayValue: "Company and/or Label", editable: false },
        "feeAdvance": { id: 4, displayValue: "Fee / Advance Payment", editable: true },
        "recouplable": { id: 6, displayValue: "Recoupable type (Non, Half or Full)", editable: true },
        "dueDateFee": { id: 5, displayValue: "Due Date for Fee / Advance Payment", editable: true },
        "masterPoints": { id: 7, displayValue: "Master royalty points", editable: true },
        "royalty": { id: 8, displayValue: "Royalty", editable: true },
        "publishing": { id: 9, displayValue: "Publishing", editable: true },
        "creditDetails": { id: 10, displayValue: "Credit Details", editable: false }
    }

    const emptyContractDetails: ContractDetails = {
        "artist": "",
        "producers": "",
        "companyLabel": "",
        "creditDetails": "",
        "dueDateFee": "",
        "feeAdvance": "",
        "masterPoints": "",
        "publishing": "",
        "recouplable": "",
        "royalty": ""
    };

    // console.log(JSON.stringify(contractDetails))

    return (
        <div className="w-full  flex flex-col bg-gray-800 text-white font-medium py-4 px-6 rounded-lg transition">
            <div className={`flex flex-col justify-between border-b py-2 ${contractDetails ? "border-blue-400" : "border-gray-400"}`}>
                <span className="w-full text-xl">{contractDetails ? "Contract Details" : "Redliner AI is extracting Contract Details..."}</span>
            </div>
            {contractDetails && contractDetails.original ? (
                Object.entries(contractDetails.original)
                    .filter(([key, value]) => (value !== "undefined" && value !== "[undefined]"))
                    //.sort(([keyA], [keyB]) => contractDetailsMap[keyA].id - contractDetailsMap[keyB].id)
                    .map(([key, value], index) => (<ContractSummaryEntry key={index} entryId={key} entryKey={contractDetailsMap[key].displayValue} editedValue={contractDetails.modified[key]} entryValue={value} isEditable={editable == false ? false : contractDetailsMap[key].editable} setContractDetails={setContractDetails} artist={artist} />))
            ) : (
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0.1 }}
                        animate={{ opacity: 0.6 }}
                        exit={{ opacity: 0.6 }}
                        transition={{ duration: 1, repeat: Infinity, repeatType: "reverse" }}
                    >
                        <motion.div
                            animate={{ opacity: [0.1, 0.6, 0.1] }}
                            transition={{ duration: 1.5, repeat: Infinity }}
                        >
                            {Object.entries(emptyContractDetails)
                                .filter(([key, value]) => (value !== "undefined"))
                                //.sort(([keyA], [keyB]) => contractDetailsMap[keyA].id - contractDetailsMap[keyB].id)
                                .map(([key, value], index) => (
                                    <ContractSummaryEntry key={index} entryId={key} entryKey={contractDetailsMap[key].displayValue} entryValue={""} isEditable={false} setContractDetails={setContractDetails} artist={artist} />
                                ))}
                        </motion.div>
                    </motion.div>

                </AnimatePresence>

            )}
        </div>
    );
};

export default ContractSummary;