import { motion } from "framer-motion";
import { Brain, FileText, Search } from "lucide-react";
import React from 'react';
import BeatSequencer from "./BeatSequencer.tsx";

const LoadingAnimation: React.FC = () => {
    return (
        <div className="h-full w-full flex flex-col items-center justify-center bg-gray-800 m-5 rounded-lg">
            {/* <BeatSequencer /> */}
            <motion.div
                animate={{ opacity: [0.1, 0.6, 0.1] }}
                transition={{ duration: 1.5, repeat: Infinity }}
                className="relative flex items-center space-x-4"
            >
                {/* Brain Icon */}
                <motion.div
                    className="text-gray-200"
                    animate={{ rotate: [0, 10, -10, 0] }}
                    transition={{ repeat: Infinity, duration: 1.5, ease: "easeInOut" }}
                >
                    <Brain size={30} />
                </motion.div>

                <motion.div
                    className="text-gray-200"
                    animate={{ rotate: [0, 6, -6, 0] }}
                    transition={{ repeat: Infinity, duration: 0.9, ease: "easeInOut" }}
                >
                    <Search size={30} />
                </motion.div>

                {/* Document Icon */}
                <motion.div
                    className="text-gray-200"
                    animate={{ rotate: [0, 6, -6, 0] }}
                    transition={{ repeat: Infinity, duration: 0.9, ease: "easeInOut" }}
                >
                    <FileText size={30} />
                </motion.div>
            </motion.div>

            <span className="text-lg text-gray-200 opacity-20 mt-10 ">This can take a minute...</span>

        </div>
    );
};

export default LoadingAnimation;