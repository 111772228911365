import React, { useState, useRef, useEffect } from 'react';
import { saveAs } from 'file-saver';
import DocxViewer from "./components/DocxPreview/DocxViewer.tsx";
import LoadingAnimation from './components/Loading/LoadingAnimation.tsx';
import SuggestionsInterface from './components/Suggestions/SuggestionsInterface.tsx';
import { AcceleratedAnimation, AnimatePresence, motion } from 'framer-motion';
import uuid from 'react-uuid';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContractSummary, { } from './components/ContractSummary/ContractSummary.tsx';
import { Artist, Chat, ContractDetails, ContractDetailsChanges, User } from './types/TypesAndFunctions.tsx';
import LoadingDots from './components/Loading/LoadingDots.tsx';
import { ArrowDownCircle } from 'lucide-react';
import Sidebar from './sidebar/Sidebar.tsx';
import { PageStatus, Suggestion, RedlinerFile } from './types/TypesAndFunctions.tsx';
import JSZip from "jszip";


const ContractRedlineTool: React.FC = () => {

  const [status, setStatus] = useState<PageStatus>(PageStatus.EMPTY);

  const [chats, setChats] = useState<Chat[]>([]);
  const [currentChat, setCurrentChat] = useState<Chat | null>(null);

  const [receivedSuggestions, setReceivedSuggestions] = useState<Suggestion[]>([]);

  const [contractDetails, setContractDetails] = useState<ContractDetailsChanges | null>(null);
  const [contractDetailsChangesAsSuggestions, setContractDetailsChangesAsSuggestions] = useState<Suggestion[]>([]);

  const [error, setError] = useState<String | null>(null);
  const [isPreview, setIsPreview] = useState(true);
  const [acceptedSuggestions, setAcceptedSuggestions] = useState<Suggestion[]>([]);
  const [artist, setArtist] = useState<Artist | null>(null);

  const [user, setUser] = useState<User | null>(null);

  // const [files, setFiles] = useState<RedlinerFile[]>([{ id: 0, name: "New Document", editDate: getCurrentDate(), originalFile: null, modifiedFile: null }]);
  // const [selectedFileId, setSelectedFileId] = useState<Number>(0);

  async function fetchFile(chat_id: string, filetype: string) {
    try {

      // const url = `http://127.0.0.1:5001/files?chat_id=${chat_id}&filetype=${filetype}`;
      const url = `http://161.35.29.12:5001/files?chat_id=${chat_id}&filetype=${filetype}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        mode: "cors",
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }

      const blobFile = await response.blob()
      // return filetype == "original" ? blobToFile(blobFile) : blobFile;
      return blobFile;

    } catch (error) {
      console.error("Error fetching file:", error);
      showError("There was a problem retrieving the file from your chat!");

    }
  }

  async function fetchArtistDetails(artistName: string | null) {
    try {

      if (artistName == null) {
        console.error("Failed to fetch artist: No artistName given")
        throw new Error(`Failed to fetch artist: No artistName given`);

      }

      //const url = `http://127.0.0.1:5001/get_artist?artistName=${artistName}`;
      const url = `http://161.35.29.12:5001/get_artist?artistName=${artistName}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        mode: "cors",
      })

      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }

      const data = await response.json();

      // setReceivedSuggestions(data.data);
      console.log("data")
      console.log(data)
      // setArtist(data)
      return data;


    } catch (error) {
      console.error("Error fetching file:", error);
      showError("There was a problem retrieving the file from your chat!");

    }
  }

  function blobToFile(fileBlob: Blob | undefined, name: string) {
    if (fileBlob)
      return new File([fileBlob], "redlined_contract.docx", {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        lastModified: Date.now(),
      });
  }


  async function fetchChats() {
    if (user !== null && user.token !== null) {
      // const response = await fetch('http://127.0.0.1:5001/chats', {
      const response = await fetch('http://161.35.29.12:5001/chats', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`
        },
        mode: 'cors',
      }).then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch chats");
        }
        return response.json();
      })
        .then(async (data) => {
          // setReceivedSuggestions(data.data);
          setStatus(PageStatus.LOADING);
          console.log(data)
          if (data.chats.length > 0) {
            const fetchedChats = await Promise.all(
              data.chats.map(async (chat) => {

                var originalFile: File | undefined = undefined;
                var modifiedFile: Blob | undefined = undefined;

                switch (chat.status) {
                  case "EMPTY":
                    break;
                  case "FILE_UPLOADED":
                  case "CONTRACT_DETAILS":
                  case "SELECT_SUGGESTIONS":

                    originalFile = blobToFile(await fetchFile(chat.id, "original"), chat.name);
                    break;
                  case "FINISHED":
                    originalFile = blobToFile(await fetchFile(chat.id, "original"), chat.name);
                    modifiedFile = await fetchFile(chat.id, "modified");
                    break;
                }

                const contractDetails = chat.contractDetails.original ? { original: chat.contractDetails.original, modified: chat.contractDetails.modified ?? chat.contractDetails.original } : null;

                let artistDetails = contractDetails?.original.artist ? await fetchArtistDetails(contractDetails.original.artist) : null;

                return {
                  id: chat.id,
                  name: chat.name,
                  creationDate: chat.creation_date,
                  lastModificationDate: chat.last_modification_date,
                  status: getPageStatus(chat.status),
                  originalFile,
                  modifiedFile,
                  contractDetails: contractDetails,
                  artist: artistDetails
                };
              })
            );

            setChats(fetchedChats);

            const emptyChats = fetchedChats.filter(x => x.status == PageStatus.EMPTY);
            console.log("emptyChats")
            console.log(emptyChats)

            const initialSelectedChat = emptyChats.length > 0 ? emptyChats[0] : fetchedChats[0];

            setCurrentChat(initialSelectedChat)

            // setStatus(getPageStatus(initialSelectedChat.status))

            setArtist(initialSelectedChat.artist)


            if (initialSelectedChat.contractDetails) {
              setContractDetails({ original: initialSelectedChat.contractDetails.original, modified: initialSelectedChat.contractDetails.modified });
            }

            if (initialSelectedChat.originalFile && initialSelectedChat.status == PageStatus.SELECT_SUGGESTIONS) {
              await getSuggestions(initialSelectedChat.originalFile, initialSelectedChat.id);
            }


          } else {
            setStatus(PageStatus.EMPTY);
          }
        }).catch((err) => {
          console.log(err)
          showError("There was a problem while retrieving your chats!");

        });
    }
  }

  useEffect(() => {
    fetchChats();
  }, [user]);

  const getPageStatus = (pageStatus: string) => {
    if (pageStatus == "EMPTY") {
      return PageStatus.EMPTY
    } else if (pageStatus == "FILE_UPLOADED") {
      return PageStatus.FILE_UPLOADED
    } else if (pageStatus == "CONTRACT_DETAILS") {
      return PageStatus.CONTRACT_DETAILS
    } else if (pageStatus == "SELECT_SUGGESTIONS") {
      return PageStatus.SELECT_SUGGESTIONS
    } else if (pageStatus == "FINISHED") {
      return PageStatus.FINISHED
    } else {
      return PageStatus.EMPTY;
    }
  }

  const setNewCurrentChat = async (chat: Chat | null) => {
    console.log(chat)
    if (chat !== null) {
      console.log(chat.status)
      if (chat.status == PageStatus.EMPTY) {
        setReceivedSuggestions([]);
        setContractDetails(null);
        setContractDetailsChangesAsSuggestions([]);
      } else if (chat.status == PageStatus.FILE_UPLOADED) {
        setReceivedSuggestions([]);
        setContractDetails(null);
        setContractDetailsChangesAsSuggestions([]);
      } else if (chat.status == PageStatus.CONTRACT_DETAILS) {
        setReceivedSuggestions([]);
        setContractDetailsChangesAsSuggestions([]);
        if (chat.contractDetails) setNewContractDetails(chat.contractDetails, chat.id)
      } else if (chat.status == PageStatus.SELECT_SUGGESTIONS) {
        console.log("here")
        if (chat.contractDetails) setNewContractDetails(chat.contractDetails, chat.id)
        if (chat.contractDetails) {
          const differencesAsSuggestions = getDifferencesAsSuggestions(chat.contractDetails);
          setContractDetailsChangesAsSuggestions(differencesAsSuggestions);
        }
        await getSuggestions(chat.originalFile, chat.id);
      } else if (chat.status == PageStatus.FINISHED) {
        if (chat.contractDetails) setNewContractDetails(chat.contractDetails, chat.id);
      }

      if (chat.contractDetails) {
        const _artist = chat.artist;// await fetchArtistDetails(chat.contractDetails.original.artist ?? "")
        setArtist(_artist);
        //if (currentChat.id == chat.id) setCurrentChat(prevChat => ({ ...prevChat, artist: _artist }));
        setChats(chats => chats.map(_chat =>
          chat.id == _chat.id ? { ...chat, artist: _artist } : _chat
        ))
      } else {
        setArtist(null);
      }
      // setStatus(chat.status);
      setCurrentChat(chat);
    } else {
      setCurrentChat(null);
      initializeNewChat(false)
    }

  }

  const createNewChat = async (chatName: string = "New Contract") => {

    const emptyChats = chats.filter(x => x.status == PageStatus.EMPTY);
    if (emptyChats.length > 0) {
      setNewCurrentChat(emptyChats[0]);
      console.log("Already existing empty chat")
    } else {
      if (user && user.token !== null) {
        // await fetch('http://127.0.0.1:5001/chats', {
        await fetch('http://161.35.29.12:5001/chats', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
          },
          body: JSON.stringify({ name: chatName }),
          mode: 'cors',
        }).then((response) => {
          if (!response.ok) {
            showError("Failed to create chat!");
            throw new Error("Failed to create chat!");
          }
          return response.json();
        })
          .then((data) => {
            if (data.chat_id !== null) {
              const newChat: Chat = {
                id: data.chat_id,
                name: data.name,
                creationDate: data.creation_date,
                lastModificationDate: null,
                status: PageStatus.EMPTY,
                originalFile: null,
                modifiedFile: null,
                contractDetails: null,
                artist: null
              };

              setChats(prev => {
                console.log("Previous state:", prev);
                return Array.isArray(prev) ? [...prev, newChat] : [newChat];
              });
              setNewCurrentChat(newChat);
            } else {
              throw new Error("Failed to create chat! No Chat_id");
            }
          })
          .catch((err) => {
            showError("There was a problem while trying to process the contract");

          });
      }
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (!selectedFile) return;
    await handleFile(selectedFile);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files && event.dataTransfer.files[0];
    if (!droppedFile) return;
    await handleFile(droppedFile);
  };
  const handleFile = async (file: File) => {
    const chat_id = await createFile(file, "original");
    console.log("chat_id");
    console.log(chat_id);

    if (chat_id) {
      await extractContractDetails(file, chat_id);
    } else {
      showError("Failed to create file, no chat_id returned.");
    }
  }

  const createFile = async (file: File, type: string) => {
    console.log("start");
    console.log(currentChat?.id);

    const formData = new FormData();
    formData.append('file', file);
    if (currentChat?.id) formData.append('chat_id', currentChat.id);
    formData.append('type', type);

    if (user && user.token !== null) {
      try {
        let chat_id: string | null = null;
        // const response = await fetch('http://127.0.0.1:5001/files', {
        const response = await fetch('http://161.35.29.12:5001/files', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`,
          },
          body: formData,
          mode: 'cors',
        })
        if (!response.ok) {
          throw new Error("Failed to create file");
        }

        const data = await response.json();

        console.log(data);

        // Check if there's a currentChat or if we need to create a new one
        if (currentChat?.id) {
          // If currentChat already exists, update the originalFile and chat_id
          setCurrentChat(prevChat => {
            return prevChat ? { ...prevChat, name: data.chat.name, originalFile: file } : prevChat;
          });
          chat_id = currentChat.id;  // Use currentChat.id directly
        } else {
          // Create a new chat from the server response
          const chat = data.chat;
          const newChat: Chat = {
            id: chat.id,
            name: chat.name,
            creationDate: chat.creation_date,
            lastModificationDate: chat.last_modification_date,
            status: getPageStatus(chat.status),
            originalFile: file,
            modifiedFile: null,
            artist: null,
            contractDetails: chat.contractDetails
          };

          setNewCurrentChat(newChat);
          setChats(prev => {
            return Array.isArray(prev) ? [...prev, newChat] : [newChat];
          });

          console.log("chats")
          console.log(chats)

          chat_id = chat.id;
        }

        return chat_id;
      } catch (err) {
        showError("There was a problem while trying to create the file");
        return null
      }
    }

  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const getDifferencesAsSuggestions = (contractDetailsChanges: ContractDetailsChanges) => {
    return Object.keys(contractDetailsChanges.original).reduce<
      { section: string; original_text: string; suggested_text: string }[]
    >((diff, key) => {
      const typedKey = key as keyof ContractDetails;

      if (
        contractDetailsChanges.modified !== null &&
        contractDetailsChanges.original[typedKey] !== contractDetailsChanges.modified[typedKey]
      ) {
        return [
          ...diff,
          {
            section: "Manual change", // Leave section empty
            original_text: contractDetailsChanges.original[typedKey] ?? "", // Handle null values
            suggested_text: contractDetailsChanges.modified[typedKey] ?? "", // Handle null values
          },
        ];
      }
      return diff;
    }, []);
  };

  const createContractDetails = async (type: string) => {

    const body = JSON.stringify({
      chat_id: currentChat.id,
      chat_id_original: type === "original" ? currentChat.id : null,
      chat_id_edited: type === "original" ? null : currentChat.id,
      artist: contractDetails.original.artist,
      producers: (type === "original" ? contractDetails.original : contractDetails.modified).producers,
      companyLabel: (type === "original" ? contractDetails.original : contractDetails.modified).companyLabel,
      feeAdvance: (type === "original" ? contractDetails.original : contractDetails.modified).feeAdvance,
      recouplable: (type === "original" ? contractDetails.original : contractDetails.modified).recouplable,
      dueDateFee: (type === "original" ? contractDetails.original : contractDetails.modified).dueDateFee,
      masterPoints: (type === "original" ? contractDetails.original : contractDetails.modified).masterPoints,
      royalty: (type === "original" ? contractDetails.original : contractDetails.modified).royalty,
      publishing: (type === "original" ? contractDetails.original : contractDetails.modified).publishing,
      creditDetails: (type === "original" ? contractDetails.original : contractDetails.modified).creditDetails,
    })

    if (user && user.token !== null) {

      // await fetch('http://127.0.0.1:5001/contract_details', {
      await fetch('http://161.35.29.12:5001/contract_details', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`,
          'Content-Type': 'application/json'
        },
        body: body,
        mode: 'cors',
      }).then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save Contract Details");
        }
        return response.json();
      })
        .then((data) => {
          setChatStatus(currentChat.id, PageStatus.CONTRACT_DETAILS);
        })
        .catch((err) => {
          showError("There was a problem while trying to save Contract Details");
        });
    }
  }

  const generateContractDetailsModifications = async () => {
    const differencesAsSuggestions = getDifferencesAsSuggestions(contractDetails);
    setContractDetailsChangesAsSuggestions(differencesAsSuggestions);
    console.log(contractDetails)
    console.log(differencesAsSuggestions)
    // await createContractDetails("original");
    await createContractDetails("modified");

  }

  const getSuggestions = async (file: File | null, chat_id: string) => {

    console.log("GetSuggestions")

    setChatStatus(chat_id, PageStatus.SELECT_SUGGESTIONS);

    if (!file) {
      showError("There is no file to be processed!");
      return;
    }

    if (!file.name.endsWith(".docx")) {
      showError("Invalid file format. Please upload a .docx file.");
      setChatStatus(chat_id, PageStatus.CONTRACT_DETAILS)
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('chat_id', chat_id);

    if (user && user.token !== null) {

      // await fetch('http://127.0.0.1:5001/get_suggestions', {
      await fetch('http://161.35.29.12:5001/get_suggestions', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`
        },
        body: formData,
        mode: 'cors',
      }).then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch suggestions");
        }
        return response.json();
      })
        .then((data) => {
          if (data.data.length > 0) {
            // setReceivedSuggestions(data.data);
            console.log(data.data);

            const fetchedSuggestions = data.data.map((x) => {
              return {
                id: x.id,
                section: x.section,
                original_text: x.original_text,
                suggested_text: x.suggested_text,
                applied: x.applied
              }
            });
            setReceivedSuggestions(
              fetchedSuggestions
            );

            setChatStatus(chat_id, PageStatus.SELECT_SUGGESTIONS);
          } else {
            showError("It wasn't possible to load suggestions from AI. Try again");
            setChatStatus(chat_id, PageStatus.CONTRACT_DETAILS);
          }
        })
        .catch((err) => {
          showError("There was a problem while trying to process the contract");
        });
    }
  }

  type Suggestion = {
    id: number;
    applied: boolean;
    section: string;
    original_text: string;
    suggested_text: string;
  };

  const applySuggestions = async (suggestions: Suggestion[], chat_id: string) => {

    console.log("contractDetailsChangesAsSuggestions")
    console.log(contractDetailsChangesAsSuggestions)
    console.log("suggestions")
    console.log(suggestions)

    const redlines = [...contractDetailsChangesAsSuggestions, ...suggestions];
    console.log("redlines")
    console.log(redlines)

    if (redlines.length === 0) {
      showError('There are no redlines to be applied');
      return;
    }

    const formData = new FormData();
    formData.append('file', currentChat.originalFile);
    formData.append('data', JSON.stringify({
      data: {
        redlines: redlines
      },
      chat_id: chat_id
    }));

    try {
      if (user && user.token !== null) {
        // const response = await fetch('http://127.0.0.1:5001/apply_suggestions', {
        const response = await fetch('http://161.35.29.12:5001/apply_suggestions', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
          },
          body: formData,
          mode: 'cors',
        });

        if (response.ok) {
          const blob = await response.blob();
          setCurrentChat(prev => { return { ...prev, modifiedFile: blob } });
          setChats(chats => chats.map(chat =>
            chat.id == chat_id ? { ...chat, modifiedFile: blob } : chat
          ))
          console.log("ModifiedFile received!");
        } else {
          showError("Failed to process the document");
          return;
        }
      }
    } catch (error) {
      showError("Error processing document!");
      console.error('Error processing document:', error);
    }
  }

  const extractContractDetails = async (contractFile: File = currentChat.originalFile, chat_id: string) => {

    setChatStatus(chat_id, PageStatus.CONTRACT_DETAILS);
    if (!contractFile) {
      showError("There is no file to be processed!");
      return;
    }

    // Validate file type
    if (!contractFile.name.endsWith(".docx")) {
      showError("Invalid file type. Please upload a .docx file.");
      return;
    }

    // Validate file size
    console.log("Uploading file:", contractFile.name, "Size:", contractFile.size);
    if (contractFile.size === 0) {
      showError("File is empty!");
      return;
    }

    const formData = new FormData();
    formData.append('file', contractFile);
    formData.append('chat_id', chat_id); // Fixing how chat_id is sent

    try {
      if (user && user.token !== null) {
        // await fetch('http://127.0.0.1:5001/extract_contract_details', {
        await fetch('http://161.35.29.12:5001/extract_contract_details', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',  // Ensure JSON response is accepted
            'Authorization': `Bearer ${user.token}`
          },
          body: formData, // Do NOT manually set Content-Type
          mode: 'cors',
        })
          .then((response) => {
            if (!response.ok) {
              showError("Failed to load contract details");
              throw new Error("Failed to extract contract details");
            }
            return response.json();
          })
          .then(async (data) => {
            if (data.contractDetails.length !== 1) {
              showError("Failed to load contract details. Too many details");
              setChatStatus(chat_id, PageStatus.EMPTY);
              throw new Error("Failed to extract contract details");
            }

            const _artist = await fetchArtistDetails(data.contractDetails[0].artist)
            setArtist(_artist)

            const _contractDetails: ContractDetailsChanges = { original: data.contractDetails[0], modified: data.contractDetails[0] };
            setContractDetails(_contractDetails);
            setChatStatus(chat_id, PageStatus.CONTRACT_DETAILS);

            console.log("chats.length")
            console.log(chats.length)

            if (chats.length > 0) {
              setChats(chats => chats.map(chat =>
                chat.id == chat_id ? { ...chat, artist: _artist, contractDetails: _contractDetails } : chat
              ))
            }

            if (currentChat.id == chat_id) {
              setCurrentChat(prevChat => ({ ...prevChat, artist: _artist, contractDetails: _contractDetails }));
            }
          })
          .catch((err) => {
            console.error("Fetch error:", err);
            showError("Failed to load contract details.");
            // Todo: Delete File from chat
          });
      }
    } catch (error) {
      console.error('Error processing document:', error);
      showError("Error processing document!");
    }

  };

  const setChatStatus = (chat_id: string | null, status: PageStatus) => {
    setStatus(status);
    if (chat_id) {
      if (currentChat && currentChat.id == chat_id) setCurrentChat(prevChat => ({ ...prevChat, status: status }));
      setChats(chats => chats.map(chat =>
        chat.id == chat_id ? { ...chat, status: status } : chat
      ))
    }
  }
  const downloadModifiedFile = () => {
    if (!currentChat.modifiedFile) return;
    saveAs(currentChat.modifiedFile, 'redliner_' + currentChat.modifiedFile.name);
  };

  const downloadOriginalDocx = () => {
    if (!currentChat.originalFile) return;
    saveAs(currentChat.originalFile, 'original_' + currentChat.originalFile.name);
  };

  const finalize = async (acceptedSuggestions: Suggestion[]) => {
    console.log(acceptedSuggestions)
    // if (acceptedSuggestions.length > 0) 
    await applySuggestions(acceptedSuggestions, currentChat.id);
    setChatStatus(currentChat.id, PageStatus.FINISHED)
  }

  const showError = (errorMessage: string) => {
    setError(errorMessage);
    toast.error(errorMessage); // Show toast notification
  };

  const initializeNewChat = (_createNewChat: boolean = true) => {

    setReceivedSuggestions([]);
    setContractDetails(null);
    setContractDetailsChangesAsSuggestions([]);
    setArtist(null);
    setStatus(PageStatus.EMPTY);
    if (_createNewChat) createNewChat();

  }

  const setNewContractDetails = (contractDetails: ContractDetailsChanges, chat_id: string) => {
    setContractDetails(contractDetails);
    if (currentChat.id == chat_id) setCurrentChat(prevChat => ({ ...prevChat, contractDetails: contractDetails }));
    setChats(chats => chats.map(chat =>
      chat.id == chat_id ? { ...chat, contractDetails: contractDetails } : chat
    ))

  }

  const handleDeleteChat = async (chat_id: string) => {
    // await fetch('http://127.0.0.1:5001/delete_chat/' + chat_id, {
    await fetch('http://161.35.29.12:5001/delete_chat/' + chat_id, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',  // Ensure JSON response is accepted
        'Authorization': `Bearer ${user.token}`
      },
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          showError("Failed to delete chat");
          throw new Error("Failed to delete chat");
        }
        setChats((chats) => chats.filter(x => x.id !== chat_id))
        initializeNewChat();
        return response.json();
      }).catch((err) => {
        console.error("Fetch error:", err);
        showError("Failed to delete chat.");
      });
  }

  return (
    <div className="w-full flex min-h-screen bg-gray-900">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar aria-label={undefined} />
      <Sidebar initializeNewChat={initializeNewChat} chats={chats} currentChat={currentChat} setChats={setChats} setCurrentChat={setNewCurrentChat} artist={chats.artist} setUser={setUser} user={user} showError={showError} />

      <div className="w-4/5 flex-1 p-4">
        <div className="bg-gray-700 shadow-md rounded-lg p-6 w-full h-full mx-auto flex flex-col justify-center items-center">
          <h1 className=" h-[5%]  text-center text-gray-50 mb-5">
            <span className="font-semibold overflow-hidden text-ellipsis whitespace-nowrap" style={{ fontSize: "clamp(0.7rem, 4vw, 1.4rem)" }}>
              {currentChat && currentChat.originalFile != null && currentChat.originalFile.name !== null ? currentChat.name : "New Contract"}
            </span>
            {/*currentChat && currentChat.creationDate &&
              <span className="text-lg ml-3 text-gray-400">
                {"(Creation date: " + currentChat.creationDate + ")"}
              </span>
            */}
            {currentChat && currentChat.status !== PageStatus.EMPTY && (
              <button
                onClick={() => handleDeleteChat(currentChat.id)}
                className="ml-3 text-red-500 hover:text-red-700"
              >
                Delete
              </button>
            )}
          </h1>


          <AnimatePresence mode="wait">
            <motion.div
              key="content"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
              className="w-[90%] h-[90%] flex flex-col items-center justify-center"
            >

              {/* File Upload State */}
              {((!currentChat && status == PageStatus.EMPTY) || (currentChat && [PageStatus.EMPTY, PageStatus.FILE_UPLOADED].includes(currentChat.status))) && (
                <>
                  <div
                    className="mt-6 border border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer hover:border-blue-500 hover:bg-gray-800 transition w-full h-full flex items-center justify-center"
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleDrop}
                    onClick={handleClick}
                  >

                    <input
                      type="file"
                      accept=".docx"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      className="hidden"
                    />
                    <p className="text-gray-50">
                      {currentChat && currentChat.originalFile ? `Current file: "${currentChat.originalFile.name}"` : "Drag & drop a DOCX file here or click to upload"}
                    </p>
                  </div>
                  {
                    currentChat && currentChat.status == PageStatus.FILE_UPLOADED && <button
                      onClick={async () => await extractContractDetails(currentChat.file, currentChat.id)}
                      className="h-[6%] w-full flex justify-center items-center gap-2 text-white font-medium py-2 rounded-lg bg-blue-500 hover:bg-blue-600 transition mt-5"
                    >
                      <span>
                        Extract Contract Details
                      </span>
                    </button>
                  }
                </>
              )}

              {/* Existing content handling */}
              {currentChat && currentChat.status === PageStatus.CONTRACT_DETAILS && (
                <>
                  <div className="h-[500px] w-full overflow-scroll">
                    <ContractSummary contractDetails={contractDetails} setContractDetails={setNewContractDetails} artist={artist ?? null} />
                  </div>

                  {currentChat.contractDetails ?
                    <button
                      onClick={async () => {
                        await generateContractDetailsModifications();
                        await getSuggestions(currentChat.originalFile, currentChat.id);
                      }}
                      className={`w-full mt-5 ${currentChat.contractDetails ? "bg-blue-500 hover:bg-blue-600" : "bg-gray-500 hover:bg-gray-600"}  text-white font-medium py-2 rounded-lg transition`}
                      disable={currentChat.contractDetails}
                    >
                      {"Get More suggestions from AI"}
                    </button>
                    :
                    <LoadingDots />
                  }
                </>
              )}

              {currentChat && currentChat.status === PageStatus.SELECT_SUGGESTIONS && receivedSuggestions.length == 0 && <LoadingAnimation />}

              {currentChat && currentChat.status === PageStatus.LOADING && <LoadingAnimation />}
              {!currentChat && status === PageStatus.LOADING && <LoadingAnimation />}

              {currentChat && currentChat.status === PageStatus.SELECT_SUGGESTIONS && receivedSuggestions.length > 0 && (
                <div className="w-full h-full flex flex-col items-center justify-center gap-5">
                  <SuggestionsInterface suggestions={receivedSuggestions} acceptedSuggestions={acceptedSuggestions} setAcceptedSuggestions={setAcceptedSuggestions} />
                  <button
                    onClick={async () => {
                      console.log(acceptedSuggestions)
                      await finalize(acceptedSuggestions)
                    }}
                    className="w-full  text-white font-medium py-2 rounded-lg bg-blue-500 hover:bg-blue-600 transition"
                  >
                    Finalize
                  </button>
                  <button
                    onClick={async () => {
                      await finalize([]);
                    }}
                    className="w-full mt-2 bg-gray-400 text-white font-medium py-2 rounded-lg hover:bg-gray-500 transition"
                  >
                    Skip
                  </button>
                </div>
              )}

              {currentChat && currentChat.status == PageStatus.FINISHED &&
                <div className="w-full h-full flex flex-col items-center justify-center gap-5 mt-5">
                  <div className="flex items-center space-x-3 bg-gray-500 p-1 rounded-xl px-5 justify-center gap-2 h-[8%]">
                    <span className={`${isPreview ? "" : "text-gray-600"}`}>See Preview</span>
                    <div
                      className="relative w-14 h-7 bg-gray-400 rounded-full cursor-pointer p-1 flex items-center"
                      onClick={() => setIsPreview(!isPreview)}
                    >
                      <div
                        className={`w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${isPreview ? "translate-x-0" : "translate-x-7"}`}
                      />
                    </div>
                    <span className={`${!isPreview ? "" : "text-gray-600"}`}>See summary</span>
                  </div>
                  <div className="h-[80%]">
                    {isPreview ?
                      <div className="h-[500px] w-full overflow-scroll">
                        <DocxViewer file={currentChat.modifiedFile} />
                      </div>
                      :
                      <div className="h-[500px] w-full overflow-scroll">
                        <ContractSummary contractDetails={contractDetails} setContractDetails={setNewContractDetails} editable={false} artist={artist ?? null} setArtist={setArtist} />
                      </div>}
                  </div>
                  <button
                    onClick={downloadModifiedFile}
                    className="h-[6%] w-full flex justify-center items-center gap-2 text-white font-medium py-2 rounded-lg bg-blue-500 hover:bg-blue-600 transition"
                  >
                    <ArrowDownCircle size={20} />
                    <span>
                      Download Modified Contract

                    </span>
                  </button>

                  <button
                    onClick={downloadOriginalDocx}
                    className="h-[6%] w-full flex justify-center items-center gap-2 text-white font-medium py-2 rounded-lg bg-gray-500 hover:bg-gray-600 transition"
                  >
                    <ArrowDownCircle size={20} />
                    <span>
                      Download Original Contract
                    </span>
                  </button>

                </div>
              }

            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div >
  );
};

export default ContractRedlineTool;