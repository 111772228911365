import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Artist, Chat, RedlinerFile, User } from '../types/TypesAndFunctions';
import { ChevronLeftIcon, ChevronRightIcon, UserCircleIcon } from "lucide-react";
import { useState } from "react";

const Sidebar: React.FC<{
    initializeNewChat: () => void,
    chats: Chat[] | null,
    currentChat: Chat | null,
    setChats: (chats: Chat[]) => void,
    setCurrentChat: (newChat: Chat) => void,
    user: User | null,
    setUser: (user: User | null) => void,
    showError: (errorMessage: string) => void,
    artist: Artist | null
}> = ({
    initializeNewChat,
    chats,
    currentChat,
    setChats,
    setCurrentChat,
    user,
    setUser,
    showError,
    artist
}) => {
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [isCollapsed, setIsCollapsed] = useState(false);

        const [username, setUsername] = useState<string>(""); // envyfeedsego");
        const [email, setEmail] = useState<string>(""); // envyfeedsego@gmail.com");
        const [password, setPassword] = useState<string>(""); //envyfeedsego");

        const signup = async () => {
            // await fetch('http://127.0.0.1:5001/register', {
            await fetch('http://161.35.29.12:5001/register', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'  // Ensure JSON is sent
                },
                body: JSON.stringify({
                    username: username,
                    email: email, // There was a mistake: You assigned email as username
                    password: password
                }),
                mode: 'cors',
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Oops! Register failed. Maybe user already exists 👀!");
                    }
                    return response.json();
                })
                .then(async (data) => {
                    console.log(data);
                    await login(username, email, password);
                    setUsername("");
                    setPassword("");
                    setPassword("");

                })
                .catch((err) => {
                    console.log("Oops! Register failed. Maybe user already exists 👀", err);
                    showError("Oops! Register failed. Maybe user already exists 👀");
                });
        };


        const login = async (username: string, email: string, password: string) => {
            // await fetch('http://127.0.0.1:5001/login', {
            await fetch('http://161.35.29.12:5001/login', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'  // Ensure JSON is sent
                },
                body: JSON.stringify({
                    username: username,
                    password: password
                }),
                mode: 'cors',
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Oops! Login failed. Check your credentials 👀");
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    setUser({ name: username, email: email, token: data.access_token });
                    setIsModalOpen(false);
                })
                .catch((err) => {
                    console.log("Oops! Login failed. Check your credentials 👀", err);
                    showError("Oops! Login failed. Check your credentials 👀")
                    setPassword("")
                });
        }

        const logout = () => {
            setUser(null);
            setChats([]);
            setCurrentChat(null);
            setIsModalOpen(false);
            setUsername("");
            setEmail("");
            setPassword("");
        }

        return (
            <AnimatePresence mode="wait">
                <motion.div
                    key="sidebar"
                    initial={{ width: isCollapsed ? "4rem" : "20%" }}
                    animate={{ width: isCollapsed ? "4rem" : "20%" }}
                    exit={{ width: "4rem" }}
                    transition={{ duration: 0.3 }}
                    className="flex flex-col items-center p-4 bg-gray-800 text-white h-screen relative z-50"
                >
                    <div className="flex w-full items-start justify-between mb-6">
                        <h1 className="text-2xl font-semibold text-center text-gray-200 overflow-hidden text-ellipsis text-nowrap">readline.ai</h1>
                        <button
                            className="bg-blue-500 p-3 h-8 flex items-center justify-center rounded-full hover:bg-blue-600 transition"
                            onClick={initializeNewChat}
                        >
                            <span className="text-md">+</span>
                        </button>
                    </div>

                    <div className="flex flex-col w-full flex-grow">
                        {!chats && chats.length == 0 && !isCollapsed &&
                            <div className="w-full h-full flex items-center justify-center text-gray-400 text-center">
                                <span>You haven't processed any contract yet!</span>
                            </div>}
                        {chats && chats.length > 0 && chats.slice().reverse().map((chat: Chat, index) => (
                            <>
                                {!isCollapsed ?
                                    <motion.div
                                        key={index}
                                        initial={{ opacity: 0, x: -10 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        exit={{ opacity: 0, x: 10 }}
                                        transition={{ duration: 0.2 }}
                                        className={`w-full h-[8%] flex justify-between items-center p-2 mb-2 ${chat.id === currentChat.id ? "bg-gray-600 border-2 border-gray-500" : "bg-gray-700 hover:bg-gray-600"
                                            } rounded-lg cursor-pointer transition`}
                                        onClick={async () => {

                                            await setCurrentChat(chat)
                                        }
                                        }
                                    >
                                        <>
                                            {chat.artist ?
                                                <img
                                                    src={chat.artist.image}
                                                    alt={chat.artist.name}
                                                    className={`w-9 aspect-square rounded-full object-fill mr-2`}
                                                /> :
                                                <div className="w-9 aspect-square rounded-full bg-gray-500 mr-2" />
                                            }
                                            <div className="w-[90%] h-full flex flex-col items-start justify-center">
                                                <span className="text-xs text-gray-300 max-w-full pr-5 overflow-hidden text-ellipsis whitespace-nowrap">{chat && chat.name ? chat.name : "New Contract"}</span>
                                                {chat.creationDate && <span className="text-[10px] text-gray-400 overflow-hidden text-ellipsis ">{chat.creationDate}</span>}
                                            </div>
                                        </>
                                    </motion.div>
                                    :
                                    <div className={`w-full h-[8%] flex justify-between items-center mb-2 rounded-lg cursor-pointer transition`}>
                                        {chat.artist ?
                                            <img
                                                src={chat.artist.image}
                                                alt={chat.artist.name}
                                                className={`w-8 aspect-square rounded-full object-fill ${!isCollapsed && false && "mr-2"}`}
                                            /> :
                                            <div className={`w-8 aspect-square rounded-full bg-gray-500 ${!isCollapsed && false && "mr-2"} `} />
                                        }
                                    </div>
                                }
                            </>
                        ))}
                    </div >
                    <div className="w-full flex items-center justify-center mt-auto border-t border-gray-600 pt-3 gap-2">
                        {!isCollapsed && (
                            <>
                                {user !== null ? (
                                    <button
                                        className="flex items-center gap-2 bg-gray-700 hover:bg-gray-600 p-2 rounded-lg transition w-full justify-center"
                                        onClick={() => setIsModalOpen(true)}
                                    >
                                        {
                                            user.avatar ? (
                                                <img src={user.avatar} alt="Avatar" className="w-6 h-6 rounded-full" />
                                            ) : (
                                                <UserCircleIcon className="w-6 h-6 text-gray-400" />
                                            )
                                        }
                                        <span className="text-sm text-gray-200">{user.name}</span>
                                    </button>
                                ) : (
                                    <button
                                        className="bg-blue-500 p-2 rounded-lg w-full text-center hover:bg-blue-600 transition"
                                        onClick={() => setIsModalOpen(true)}
                                    >
                                        Sign Up / Login
                                    </button>
                                )}

                            </>
                        )}
                        <button
                            className="bg-gray-700 p-2 rounded-full hover:bg-gray-600 transition"
                            onClick={() => setIsCollapsed(!isCollapsed)}
                        >
                            {isCollapsed ? <ChevronRightIcon className="w-6 h-6 text-gray-300" /> : <ChevronLeftIcon className="w-6 h-6 text-gray-300" />}
                        </button>
                    </div>

                    {
                        isModalOpen && (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
                                onClick={() => setIsModalOpen(false)}
                            >
                                <motion.div
                                    initial={{ scale: 0.8 }}
                                    animate={{ scale: 1 }}
                                    exit={{ scale: 0.8 }}
                                    className="bg-gray-800 p-6 rounded-lg shadow-lg w-[50%]"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <h2 className="text-xl font-semibold text-gray-200 mb-4">Account Details</h2>
                                    {user ? (
                                        <div className="flex flex-col items-center">
                                            {user.avatar && <img src={user.avatar} alt="Avatar" className="w-16 h-16 rounded-full mb-3" />}
                                            <span className="text-lg text-gray-200">User: {user.name}</span>
                                            <span className="text-lg text-gray-200">Email: {user.email}</span>
                                            <button className="mt-4 bg-red-500 hover:bg-red-600 p-2 rounded-lg w-full text-white" onClick={logout}>Log Out</button>
                                        </div>
                                    ) : (
                                        <div className="w-full text-center justify-center items-center px-10 p-5">
                                            <p className="text-gray-300 pb-5 mb-5 border-b-2 border-gray-500">You are not signed in.</p>

                                            <input
                                                placeholder="User name"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                className="w-full border px-3 py-2 rounded-md mb-2 focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
                                                autoFocus
                                            />
                                            <input
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="w-full border px-3 py-2 rounded-md mb-2 focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
                                                autoFocus
                                            />
                                            <input
                                                placeholder="Password"
                                                type={"password"}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                className="w-full border px-3 py-2 rounded-md mb-2 focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
                                                autoFocus
                                            />
                                            <div className="w-full h-full flex gap-3">
                                                <button className="bg-gray-500 hover:bg-gray-600 p-2 mt-5 rounded-lg w-[80%]" onClick={signup}>Sign Up</button>
                                                <button className="bg-blue-500 hover:bg-blue-600 p-2 mt-5 rounded-lg w-[80%]" onClick={() => login(username, email, password)}>Log In</button>
                                            </div>
                                        </div>
                                    )}
                                </motion.div>
                            </motion.div>
                        )
                    }
                </motion.div >
            </AnimatePresence >)
    }

export default Sidebar;