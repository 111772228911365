
export interface Suggestion {
    id: number;
    section: string;
    original_text: string;
    suggested_text: string;
    applied: boolean;
}

export interface RedlinerFile {
    id: number;
    name: string;
    creation: string | null;
    originalFile: File | null;
    modifiedFile: Blob | null;
}

export interface User {
    name: string;
    email: string;
    token: string | null;
}

export enum PageStatus {
    EMPTY = "EMPTY",
    FILE_UPLOADED = "FILE_UPLOADED",
    CONTRACT_DETAILS = "CONTRACT_DETAILS",
    SELECT_SUGGESTIONS = "SELECT_SUGGESTIONS",
    FINISHED = "FINISHED",
    LOADING = "LOADING"
}

export interface ContractDetails {
    artist: string | null;
    producers: string | null;
    companyLabel: string | null;
    feeAdvance: string | null;
    recouplable: string | null;
    dueDateFee: string | null;
    masterPoints: string | null;
    royalty: string | null;
    publishing: string | null;
    creditDetails: string | null;
}

export interface ContractDetailsChanges {
    original: ContractDetails;
    modified: ContractDetails | null;
}

export interface Artist {
    id: string;
    name: string;
    url: string;
    image: string;
    genres: string;
}

export interface Suggestion {
    section: string;
    original_text: string;
    suggested_text: string;
}

export interface Chat {
    id: string;
    name: string;
    artist: Artist | null;
    creationDate: string;
    lastModificationDate: string | null;
    status: PageStatus;
    originalFile: File | null;
    modifiedFile: Blob | null;
    contractDetails: ContractDetailsChanges | null;
}
